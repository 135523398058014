import { useState } from "react";

export default function Header(): JSX.Element {
	var showAd = window.location.pathname == "/about" ? true : false;
	const [beta, setBeta] = useState(
		window.location.hostname == "beta.keikkakoodi.fi" ? true : false
	);
	function goHome() {
		const query = new URLSearchParams(window.location.search).get("query");
		if (query !== null) {
			localStorage.setItem("lastSearch", JSON.stringify(query));
			window.location.href = "/";
		} else {
			window.location.href = "/";
		} //else
	} //goHome

	return (
		<div className="Header">
			<a
				onClick={goHome}
				id="headerAnchor">
				<h1 id="header"> Keikkakoodi </h1>
			</a>
			{beta && (
				<div className="betaNotif">
					<h2>Käytät Keikkakoodin BETA-versiota</h2>
					<p>
						Tämä tarkoittaa että joidenkin ominaisuuksien kehitys on vielä
						kesken.
					</p>
					<a
						id="closeBeta"
						target="_blank"
						rel="noopener noreferrer"
						href="https://keikkakoodi.fi">
						Siirry pois BETA-versiosta
					</a>{" "}
					<br />
					<button onClick={() => setBeta(false)}>
						Jatka BETA-versiolla ja sulje ilmoitus
					</button>
				</div>
			)}
			{showAd && (
				<div className="androidAd">
					<h2>
						Keikkakoodi nyt myös mobiilisovelluksena! <br />{" "}
						<a
							id="androidInstallLink"
							href="https://github.com/keikkakoodi/Android/releases/download/latest/keikkakoodi.apk"
							target="_blank"
							rel="noopener noreferrer">
							Asenna
						</a>
					</h2>
				</div>
			)}
		</div>
	);
}
