import React, { Suspense, lazy } from "react";
import { Analytics } from "@vercel/analytics/react";
import ReactDOM from "react-dom/client";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";

//Styles
import "./global.css";

//Components
import Header from "./Components/Header";
import Loading from "./Components/Loading";

//Pages
const HomePage = lazy(() => import("./Home/HomePage"));
const InfoPage = lazy(() => import("./Info/InfoPage"));
const NotFoundPage = lazy(() => import("./404"));

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import Footer from "./Components/Footer";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
	<React.StrictMode>
		<Analytics />
		<div className="container">
			<Header />
			<div className="contentWrap">
				<Router>
					<Routes>
						<Route
							index
							element={
								<Suspense fallback={<Loading />}>
									<HomePage />
								</Suspense>
							}
						/>
						<Route
							path="about"
							element={
								<Suspense fallback={<Loading />}>
									<InfoPage />
								</Suspense>
							}
						/>

						<Route
							path="info"
							element={<Navigate to="/about" />}
						/>

						<Route
							path="*"
							element={
								<Suspense fallback={<Loading />}>
									<NotFoundPage />
								</Suspense>
							}
						/>
					</Routes>
				</Router>
			</div>
			{/*^ .contentWrap ^*/}
		</div>
		{/* .^ container ^ */}
		<Footer />
	</React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
