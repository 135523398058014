export default function Footer(): JSX.Element | null {
	const curPath = window.location.pathname;

	var packageJson = require("../../package.json");
	var currVersion = packageJson.version;
	const currYear = new Date().getFullYear().toString();

	if (curPath !== "/about") {
		return (
			<footer>
				<a href="/about">
					<p id="abtLink">Mikä on keikkakoodi?</p>
				</a>
				<p>
					Copyright &copy; 2023-{currYear} Leevi Saastamoinen & keikkakoodi.fi{" "}
				</p>
				<p>Keikkakoodi V{currVersion} </p>
			</footer>
		); //return
	} else {
		return <></>;
	}
}
